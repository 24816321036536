<template>
  <div>
    <a-row class="align-items-center mb-4 justify-items-center d-flex sticky-top"
      style="margin: -30px; top: 67px; padding: 0 30px 22px 30px; background: #fff">
      <a-col :span="18">
        <div class="d-flex flex-column">
          <a-breadcrumb>
            <a-breadcrumb-item><a style="color: #1890FF"
                @click="redirectToMain">Main</a></a-breadcrumb-item>
            <a-breadcrumb-item><a style="color: rgba(0, 0, 0, 0.45)" href="javascript:;">{{ informerTitle }}</a></a-breadcrumb-item>
          </a-breadcrumb>
          <h3 class="mb-0 mt-2" style="font-size: 20px">{{ informerTitle }}</h3>
        </div>
      </a-col>
      <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end">
        <div v-if="isEditMode">
          <a-popconfirm title="Sure to delete?" placement="bottom" @confirm.stop="deleteInformer">
            <a-button :loading="loading" type="danger"> Delete </a-button>
          </a-popconfirm>
          <a-button :loading="loading" type="primary" class="ml-4" @click="save">
            Save
          </a-button>
        </div>
        <div v-else class="pt-4">
          <a-button class="ml-4" @click="clearForm">
            Clear
          </a-button>
          <a-button :loading="loading" type="primary" class="ml-4" @click="save">
            Save
          </a-button>
        </div>
      </a-col>
    </a-row>
    <div style="padding: 24px; background-color: #fff">
      <a-form-model :rules="rules" :model="form" ref="form">
        <div class="d-flex flex-column informer-form">
          <a-row>
            <a-col :span="6">
              <a-form-model-item label="Name" prop="informerName" :colon="false">
                <a-input v-model="form.informerName" placeholder="Name" size="large" :disabled="loading" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <div class="d-flex flex-row align-items-start" style="gap: 1rem">
            <a-form-model-item label="Start date" prop="startDate" :colon="false" class="d-inline-block pr-1">
              <a-date-picker v-model="form.startDate" show-time format="YYYY-MM-DD HH:mm:ss" type="date" size="large"
                placeholder="Select date" :disabled="loading" />
            </a-form-model-item>
            <a-form-model-item label="End date" prop="endDate" :colon="false" class="d-inline-block pr-1">
              <a-date-picker v-model="form.endDate" show-time format="YYYY-MM-DD HH:mm:ss" type="date" size="large"
                placeholder="Select date" :disabled="loading" />
            </a-form-model-item>
          </div>
          <a-row>
            <a-col :span="3">
              <a-form-model-item prop="informerTag">
                <template #label>Tag</template>
                <a-select v-model="form.informerTag" size="large" :disabled="loading" allow-clear>
                  <a-select-option v-for="tag in tags" :key="tag.name">
                    {{ tag.name.charAt(0).toUpperCase() + tag.name.slice(1) }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="3">
              <a-form-model-item prop="informerDisplay">
                <template #label><span class="required-label">Display section</span></template>
                <a-select v-model="form.informerDisplays" mode="multiple" size="large" disabled allow-clear>
                  <a-select-option v-for="display in displays" :key="display.value">
                    {{ display.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item prop="informerApps" required :colon="false" class="d-block">
            <template #label>
              <span class="mr-4">Apps</span>
              <a @click.stop="openAddAppModal" style="color: rgb(0, 123, 255) !important; font-weight: 400">+ Add</a>
            </template>
            <div v-if="form.informerApps">
              <a-tag style="
                    margin-right: 15px;
                    padding: 1px 8px;
                    border-radius: 2px;
                  " v-for="app in form.informerApps.slice(0, 5)" :key="app.id">
                {{ app.name }}
                <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
                <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
                <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
              </a-tag>
              <a-tag v-if="form.informerApps.length > 4"> ... </a-tag>
            </div>
          </a-form-model-item>
        <a-divider class="m-0 mb-3" />
          <div>
            <div class="d-flex flex-row align-items-center justify-content-between mb-2">
              <span class="font-weight-bold font-size-16 required-label">Media</span>
              <div class="checkbox-container">
                <a-button type="link" :disabled="!isApllyToAllAvailable" @click="addToAllLangs">
                  Apply to all languages
                </a-button>
              </div>
            </div>
              <div>
                <a-tabs v-if="languages.length > 1" :default-active-key="languages[0].name" :active-key="selectedLanguage" @change="handleTabChange"
                  :tabBarStyle="{ border: 'none' }">
                  <a-tab-pane v-for="language in languages" :key="language.name" :tab="language.desc" />
                </a-tabs>
                <a-row>
                  <a-col :span="8">
                    <a-textarea v-model="form.localization[selectedLanguage]" placeholder="Enter text" :autoSize="{minRows: 3, maxRows: 4}" :showCount="true" :maxLength="250" />
                    <div class="text-count">
                      <span>{{ selectedLocalization.length }} / 250</span>
                    </div>
                  </a-col>
                </a-row>
              </div>
            <div>
              <div class="d-flex flex-row align-items-center mb-2">
                <div>
                  <span class="font-weight-bold font-size-16 mr-3">Redirect</span>
                  <a v-if="!isRedirectAvailable" @click.stop="handleAddRedirect" style="color: rgb(0, 123, 255) !important; font-weight: 400">+
                    Add</a>
                  <a-icon v-else type="delete" @click="handleClearRedirect" style="
                    color: red;
                    cursor: pointer;
                    margin-left: 8px;
                    font-size: 16px;" />
                </div>
              </div>
              <div v-if="isRedirectAvailable" class="mb-4">
                <div class="d-flex flex-column redirect-list">
                  <div class="d-flex flex-row align-items-center mb-2 mr-4" style="gap: 1rem">
                    <a-form-model-item :colon="false">
                      <template #label><span class="required-label">Name</span></template>
                      <a-input placeholder="Name" :disabled="loading" v-model="redirect.buttonName" style="max-width: 200px" size="large">
                        <template #placeholder><span style="color: #a1a1c2">Name</span></template>
                      </a-input>
                    </a-form-model-item>
                    <a-form-model-item prop="buttonColor" :colon="false">
                      <template #label><span>Button color</span></template>
                      <a-input placeholder="#112233" :disabled="loading" v-model="redirect.fontColor" style="max-width: 200px" size="large">
                      </a-input>
                    </a-form-model-item>
                    <!-- Font color and button color reversed is not a bug -->
                    <a-form-model-item prop="fontColor" :colon="false">
                      <template #label><span>Font color</span></template>
                      <a-input placeholder="#112233" :disabled="loading" v-model="redirect.buttonColor" style="max-width: 200px" size="large">
                      </a-input>
                    </a-form-model-item>
                  </div>
                  <div class="d-flex flex-row align-items-end mb-2 mr-4" style="gap: 1rem">
                    <a-form-model-item>
                      <template #label><span class="required-label">Link type</span></template>
                      <a-select v-model="redirect.linkType" size="large" :disabled="loading"
                        allow-clear>
                        <template #placeholder><span style="color: #a1a1c2">Select link type</span></template>
                        <a-select-option value="custom"> Custom </a-select-option>
                        <a-select-option value="sport"> Sport </a-select-option>
                        <a-select-option value="match"> Match </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <div v-if="redirect.linkType === 'custom'" class="d-flex flex-ro align-items-end">
                      <a-form-model-item :colon="false" class="mr-3" style="min-width: 416px">
                        <template #label><span class="required-label">Link</span></template>
                        <a-input placeholder="https://www.olimp.bet/line/1/5956496/78487617" :disabled="loading"
                          v-model="redirect.link" size="large">
                        </a-input>
                      </a-form-model-item>
                      <!-- TODO WIDTH -->
                      <a-button type="primary" size="large" class="mr-4" :disabled="loading" @click="openAddLinkTemplatesModal">
                        Create new
                      </a-button>
                      <a-form-model-item label="Select template">
                        <a-select v-model="redirect.id"
                          @change="changeRedirectTemplate(redirect.id)" size="large" :disabled="loading" allow-clear>
                          <template #placeholder><span style="color: #a1a1c2">Template</span></template>
                          <a-select-option v-for="linkTemplate in linkTemplates" :key="linkTemplate.id">
                            {{ linkTemplate.name }}
                            <span style="color: #a1a1c2;">[{{ linkTemplate.link }}]</span>
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </div>
                    <a-form-model-item v-if="redirect.linkType === 'match'"
                      :colon="false" class="mr-3" style="min-width: 416px">
                      <template #label><span class="required-label">Match link</span></template>
                      <a-input placeholder="https://www.olimp.bet/line/1/5956496/78487617" :disabled="loading"
                        v-model="redirect.link" size="large">
                      </a-input>
                    </a-form-model-item>
                    <a-form-model-item v-if="redirect.linkType === 'sport'">
                      <template #label><span class="required-label">Select sport</span></template>
                      <a-select v-model="redirect.sportId" size="large" :disabled="loading" allow-clear>
                        <template #placeholder><span style="color: #a1a1c2;">Select sport</span></template>
                        <a-select-option v-for="sport in sports" :key="sport.sport_id">
                          {{ sport.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
      <div v-if="errors.noLocalizations" style="color: red; margin-top: 8px">At least one localization is required</div>
      <div v-if="errors.isRedirectValid" style="color: red; margin-top: 8px">Redirect must have name, link type and link / sport itself</div>
      <div v-if="errors.redirectColorLength" style="color: red; margin-top: 8px">Redirect color inputs can't be longer than 7 chars</div>
    </div>
    <a-modal v-model="addAppsModal" title="Add new apps" :footer="null" width="700px" :destroyOnClose="true">
      <AppsModal @selectApps="changeSelectedApps" @close="closeAddAppsModal" :modalAppsData="modalAppsData" />
    </a-modal>
    <a-modal v-model="addLinkTemplateModal" width="395px" :destroyOnClose="true" @ok="handleAddTemplatesModal">
      <template #title><span class="modal-title">Add new template</span></template>
      <a-form-model :model="addTemplateForm" ref="templateForm" :rules="templateRules">
        <a-form-model-item label="Name" prop="name" :colon="false">
          <a-input placeholder="Name" :disabled="loading" size="large" v-model="addTemplateForm.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Link" prop="link" :colon="false">
          <a-input placeholder="https://www.olimp.bet/line/1/5956496/7848761" :disabled="loading" size="large"
            v-model="addTemplateForm.link"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import AppsModal from '@/components/custom/modals/appsModal.vue'
import ViewscreenService from '@/services/api/apiViewscreenService'
import apiClient from '@/services/axios'

const initialForm = {
  informerName: '',
  startDate: null,
  endDate: null,
  informerTag: null,
  informerDisplays: [4],
  informerApps: [],
  localization: {
    ru: '',
    kk: '',
    en: '',
    cn: '',
    uz: '',
  },
}
const initialRedirect = {
  buttonName: '',
  buttonColor: '',
  fontColor: '',
  linkType: '',
  linkName: '',
  sportId: '',
  link: '',
  id: '',
}
export default {
  name: 'informersForm',
  components: { AppsModal },
  data() {
    return {
      form: initialForm,
      redirect: initialRedirect,
      errors: {
        noLocalizations: false,
        isRedirectValid: false,
        redirectColorLength: false,
      },
      tags: [
        { name: 'new' },
        { name: 'percent' },
        { name: 'favorite' },
        { name: 'no tag' },
      ],
      displays: [
        { label: 'Live', value: 1 },
        { label: 'Promo', value: 3 },
        { label: 'Main', value: 4 },
      ],
      loading: false,
      addAppsModal: false,
      languages: ['ru'],
      selectedLanguage: 'ru',
      linkTemplates: [],
      sports: [],
      addTemplateForm: {
        name: '',
        link: '',
      },
      isRedirectAvailable: false,
      checkAllLangs: false,
      addLinkTemplateModal: false,
      informerToEdit: null,
      rules: {
        informerName: [
          {
            required: true,
            message: 'Informer name is required',
            trigger: 'blur',
          },
        ],
        startDate: [
          {
            required: true,
            message: 'Please pick start date',
            trigger: 'change',
          },
        ],
        informerTag: [
          {
            required: true,
            message: 'Informer tag is required',
            trigger: 'blur',
          },
        ],
        informerDisplays: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one section',
            trigger: 'change',
          },
        ],
        informerApps: [
          {
            type: 'array',
            required: true,
            message: 'Applications are required',
            trigger: 'change',
          },
        ],
      },
      templateRules: {
        name: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
        link: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
        ],
      },
      modalAppsData: null,
    }
  },
  computed: {
    isEditMode() {
      return !!this.informerToEdit
    },
    informerTitle() {
      return this.isEditMode ? 'Edit informer' : 'New informer'
    },
    selectedLocalization () {
      return this.form.localization[this.selectedLanguage]
    },
    isApllyToAllAvailable() {
      if (this.selectedLocalization === '') {
        return false
      }

      const nonSelectedLanguages = Object.values(this.form.localization).filter(
        (language) => language !== this.selectedLanguage,
      )

      const emptyLanguagesCount = nonSelectedLanguages.filter((currentLang) =>
        currentLang === '',
      ).length

      return emptyLanguagesCount === this.languages.length - 1
    },
  },
  async created() {
    if (this.$route.params.id) {
      await this.getInformer(this.$route.params.id)
      this.fillForm()
    }
    await this.fetchLocalizations()
    await this.fetchSports()
    await this.fetchLinkTemplates()
  },
  methods: {
    async save() {
      const isValid = await this.validateForm()
      if (!isValid) {
        return false
      }
      if (!this.isEditMode) {
        return this.createInformer()
      }
      // this.updateInformer()
    },
    async validateForm() {
      const isValid = await new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })

      let isRedirectValid = true
      let isInvalidColorLength = false

      const oneValidLocalization = Object.values(this.form.localization).some(lang => lang !== '')

      if (this.isRedirectAvailable) {
        const { buttonName, buttonColor, fontColor, link, linkType, sportId } = this.redirect
        const fieldsToValidate = {
          buttonName,
          ...(fontColor.length && { fontColor }),
          ...(buttonColor.length && { buttonColor }),
          ...(linkType !== 'sport' && { link }),
          ...(linkType === 'sport' && { sportId }),
        }
        const errorResults = Object.values(fieldsToValidate)
        isRedirectValid = !errorResults.includes('') && !errorResults.includes(undefined)
        if (fieldsToValidate.buttonColor || fieldsToValidate.fontColor) {
          isInvalidColorLength = buttonColor.length > 7 || fontColor.length > 7
        }
      }
      const errors = {
        noLocalizations: !oneValidLocalization,
        isRedirectValid: !isRedirectValid,
        redirectColorLength: isInvalidColorLength,
      }
      this.errors = { ...errors }
      if (!isValid || Object.values(this.errors).includes(true)) {
        return false
      }
      return true
    },
    addToAllLangs() {
      for (const language in this.form.localization) {
        if (language !== this.selectedLanguage) {
          this.form.localization[language] = this.selectedLocalization
        }
      }
    },
    handleTabChange(selectedLangKey) {
      if (this.loading) {
        return
      }
      this.selectedLanguage = selectedLangKey
    },
    handleAddRedirect() {
      this.isRedirectAvailable = true
    },
    handleClearRedirect() {
      this.isRedirectAvailable = false
      Object.keys(this.redirect).forEach(key => {
        this.redirect[key] = ''
      })
    },
    changeRedirectTemplate(linkId) {
      const searchTemplate = this.linkTemplates.find((template) => {
        return template.id === linkId
      })

      this.redirect.link = searchTemplate.link
      this.redirect.name = searchTemplate.name
    },
    fillForm() {
      if (this.isEditMode) {
        this.form.viewscreenName = this.informerToEdit.name
        this.form.viewscreenSegment = this.informerToEdit.segment.id
        if (this.informerToEdit.starts_at.length) {
          this.form.startDate = this.$moment(this.informerToEdit.starts_at).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss')
        }
        if (this.informerToEdit.ends_in.length) {
          this.form.endDate = this.$moment(this.informerToEdit.ends_in).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss')
        }
        this.form.informerApps = this.informerToEdit.applications
        this.modalAppsData = this.informerToEdit.applications
      }
    },
    async createInformer() {
      try {
        this.loading = true
        const informerPayload = this.getInformerPayload()
        const informerResponse = await ViewscreenService.createInformer(informerPayload)
        if (informerResponse.data.data) {
          this.$notification.success({
            message: 'Informer successfully created',
          })
          await this.addInformerSectionItem(informerResponse.data.data.id)
          // this.redirectToMain(informerResponse.data.data.id)
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      }
    },
    async updateInformer() {
      try {
        this.loading = true
        const informerPayload = this.getInformerPayload()
        const informerResponse = await ViewscreenService.updateInformer(this.informerToEdit.id, informerPayload)
        if (informerResponse.data.data) {
          this.$notification.success({
            message: 'Informer successfully updated',
          })
          this.redirectToMain()
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      }
    },
    async deleteInformer() {
      try {
        const response = await ViewscreenService.deleteInformer(this.informerToEdit.id)
        if (response.data.data) {
          this.$notification.success({
            message: 'Viewscreen successfully deleted',
          })
          this.redirectToMain()
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
      }
    },
    async addInformerSectionItem (informerId) {
      this.loading = true
      try {
        const response = await ViewscreenService.addViewscreenSectionItem(this.$route.params.sectionId, informerId)
        if (response.data.data) {
          this.$notification.success({
            message: 'Informer has been successfully added to the viewscreen section',
          })
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error while adding informer to viewscreen section',
          description: e.response.data.error.message,
        })
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    getInformerPayload() {
      const informerPayload = {
        name: this.form.informerName,
        start_date: this.$moment(this.form.startDate).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss'),
        displays: this.form.informerDisplays,
        applications: this.getApplications(),
        ...(this.form.informerTag !== 'no tag' && { tag: this.form.informerTag }),
        ...(this.redirect.fontColor && { button_font_color: this.redirect.fontColor }),
        ...(this.redirect.buttonColor && { button_color: this.redirect.buttonColor }),
        ...(this.form.endDate && { end_date: this.$moment(this.form.endDate).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss') }),
      }
      informerPayload.localization = Object.fromEntries(
        Object.entries(this.form.localization).filter(([key, value]) => value !== ''),
      )
      if (this.isRedirectAvailable) {
        informerPayload.redirect = {
          type: this.redirect.linkType,
          name: this.redirect.buttonName,
          ...(this.redirect.linkType !== 'sport' && { link: this.redirect.link }),
          ...(this.redirect.linkType === 'sport' && { sport_id: this.redirect.sportId }),
        }
      }
      return informerPayload
    },
    clearForm() {
      this.form = initialForm
      this.redirect = initialRedirect
    },
    getApplications() {
      return this.form.informerApps.map((app) => {
        return {
          application_id: app.application_id,
          min_app_version: app.min_app_version,
          max_app_version: app.max_app_version,
        }
      })
    },
    openAddAppModal() {
      if (this.loading) {
        return
      }
      this.addAppsModal = true
    },
    closeAddAppsModal() {
      this.addAppsModal = false
    },
    changeSelectedApps(apps) {
      this.closeAddAppsModal()
      this.form.informerApps = apps
      this.modalAppsData = apps
    },
    openAddLinkTemplatesModal() {
      if (this.loading) {
        return
      }
      this.addLinkTemplateModal = true
    },
    closeAddLinkTemplatesModal() {
      this.addLinkTemplateModal = false
    },
    redirectToMain(createdId) {
      this.$router.push(`/main/${this.$route.params.viewscreenId}`)
    },
    async handleAddTemplatesModal() {
      try {
        this.loading = true
        const templateResponse = await apiClient.post(
          '/admin/links',
          this.addTemplateForm,
        )
        this.linkTemplates.push(templateResponse.data.data)
        this.closeAddLinkTemplatesModal()
        this.$refs.templateForm.resetFields()
        if (templateResponse.data.error) {
          this.loading = false
          this.$notification.error({
            message: 'Error while adding templates',
            description: templateResponse.data.error.message,
          })
          return
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error while adding templates',
          description: e.response.data.error.message,
        })
        this.loading = false
      }
      this.loading = false
    },
    async fetchLocalizations() {
      this.languagesLoading = true
      const url = '/admin/localizations'
      try {
        const response = await apiClient.get(url)
        this.languages = response.data.data
      } catch (error) {
        console.log('Error while trying to get locales', error)
      } finally {
        this.languagesLoading = false
      }
    },
    async fetchSports() {
      this.loading = true
      const url = '/admin/sports?results=1000'
      try {
        const response = await apiClient.get(url)
        this.sports = response.data.data
      } catch (error) {
        console.log('Error while trying to get sports', error)
      } finally {
        this.loading = false
      }
    },
    async fetchLinkTemplates() {
      this.loading = true
      const url = '/admin/links?type=custom&results=1000'
      try {
        const response = await apiClient.get(url)
        this.linkTemplates = response.data.data
      } catch (error) {
        console.log('Error while trying to get templates', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.informer-form {
  gap: 1rem;

  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.redirect-list {
  gap: 1rem;

  .ant-form-item {
    min-width: 200px
  }
}
.modal-title {
  font-size: 16px;
  font-weight: bold;
}

.required-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222e;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.text-count {
  color: #BFBFBF;
  text-align: end;
}
</style>
